<template>
  <div>
    <c-table
      ref="tasktable"
      title="관련 순회점검 목록"
      :columns="grid.columns"
      :data="grid.data"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :expandAll="true"
      selection="multiple"
      rowKey="saiPatrolId"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="LBLREMOVE" icon="remove" @btnClicked="deleteTask" />
          <c-btn v-if="editable" label="LBLADD" icon="add" @btnClicked="addTask" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'riskPatrol',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        vendorFlag: false,
      }),
    },
    task: {
      type: Object,
      default: () => ({
        insertUrl: '',
        deleteUrl: '',
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'processName',
            field: 'processName',
            label: 'LBLPROCESS',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            label: 'LBLDEPT',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'sopMapId',
            field: 'sopMapId',
            label: '순회장소',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'patrolName',
            field: 'patrolName',
            label: '순회명',
            align: 'left',
            sortable: true,
            type: 'link',
            style: 'width:200px',
          },
          {
            name: 'patrolDate',
            field: 'patrolDate',
            label: '순회일',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'patrolCompleteFlagName',
            field: 'patrolCompleteFlagName',
            label: 'LBLPROGRESS',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      if (this.editable && !this.disabled) {
        numHeight = numHeight - 70
      }
      return numHeight + 'px';
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.patrol.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId
      };
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.popupOptions.title = "순회점검 상세"; // 순회점검 상세
      this.popupOptions.param = {
        saiPatrolId: row.saiPatrolId
      };
      this.popupOptions.target = () => import(`${"@/pages/sai/patrol/patrolDetail.vue"}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    deleteTask() {
      let selectData = this.$refs['tasktable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '제외할 행을 지정하세요.', // 제외할 행을 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGEXCEPT', // 제외하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.task.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(this.$_.map(selectData, (item) => {
                return {
                  ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId,
                  saiPatrolId: item.saiPatrolId,
                }
              }))
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$refs['tasktable'].$refs['compo-table'].clearSelection();
              this.getList();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addTask() {
      this.popupOptions.title = '순회점검 검색'; // 순회점검 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/sai/patrol/patrolPop.vue'}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeTaskPopup;
    },
    closeTaskPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let insertList = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid.data, { saiPatrolId: item.saiPatrolId }) === -1) {
            insertList.push({
              ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId,
              saiPatrolId: item.saiPatrolId,
            });
          }
        })

        this.$http.url = this.task.insertUrl;
        this.$http.type = 'POST';
        this.$http.param = insertList
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        },);
      }
    },
  }
};
</script>